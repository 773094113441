'use client';

import useOneTapSignin from './use-one-tap-sign-in';

interface Props {
  client_id: string;
}

const OneTapComponent = ({ client_id }: Props) => {
  useOneTapSignin({
    parentContainerId: 'oneTap',
    client_id,
  });

  return <div id='oneTap' className='fixed right-2 top-2 z-[100]' />;
};

export default OneTapComponent;
