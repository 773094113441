import { useEffect, useState } from 'react';

import { SignInOptions, signIn } from 'next-auth/react';
import { usePathname, useSearchParams } from 'next/navigation';

interface OneTapSigninOptions {
  parentContainerId?: string;
  client_id?: string;
}

const useOneTapSignin = (
  options?: OneTapSigninOptions &
    Pick<SignInOptions, 'redirect' | 'callbackUrl'>,
) => {
  const { parentContainerId } = options || {};
  const [isLoading, setIsLoading] = useState(false);

  const currentPath = usePathname();

  const params = useSearchParams();
  const prv = params.get('prv');

  useEffect(() => {
    if (typeof window === 'undefined' || isLoading) return;

    const { google } = window as unknown as any;

    if (!google) return;

    google.accounts.id.initialize({
      client_id: options?.client_id,
      auto_select: false,
      callback: async (response: any) => {
        setIsLoading(true);

        await signIn('googleonetap', {
          credential: response.credential,
          callbackUrl: currentPath + (prv ? `?prv=${prv}` : ''),
          ...options,
        });
        setIsLoading(false);
      },
      prompt_parent_id: parentContainerId,
      use_fedcm_for_prompt: true,
    });

    google.accounts.id.prompt();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading };
};

export default useOneTapSignin;
